import React, { FC } from 'react'

import styled from 'styled-components'
import { sizes } from '../../theme/theme'

// types
export type ContentTitleProps = {
  className?: string
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

// styles

const Container = styled.div`
  max-width: calc(88rem + 4rem);
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  &:not(:first-child) {
    margin-top: 2.5rem;
  }
  &,
  & p,
  & li {
    line-height: 1.5;
  }
  @media (min-width: ${sizes.md}px) {
    margin-top: 2rem;
    &:not(:first-child) {
      margin-top: 4rem;
    }
  }
`
const Content = styled.h2`
  margin: 0;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  @media (min-width: ${sizes.md}px) {
    font-size: 2.1rem;
    &:is(h3) {
      font-size: 1.9rem;
    }
    &:is(h4) {
      font-size: 1.7rem;
    }
    &:is(h5) {
      font-size: 1.5rem;
    }
    &:is(h6) {
      font-size: 1.4rem;
    }
  }
`

// component
const ContentTitle: FC<ContentTitleProps> = (props) => {
  const { className, children, tag } = props

  // render
  return children ? (
    <Container className={className}>
      <Content as={tag}>
        {children}
      </Content>
    </Container>
  ) : null
}

export default ContentTitle
