import React, {FC, useEffect, useMemo} from 'react'
import {IntlProvider} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {actions, selectors} from "../../redux";
import AppGate from "../AppGate";
import {useParams} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

const Page: FC = ()=>{

  const dispatch = useDispatch()
  const params = useParams();
  const json = useSelector(selectors.app.json)
  const language = useSelector(selectors.app.language)

  const translations = useMemo(()=> json?.translations ?? {},[json])

  useEffect(() => {
    const newLanguage = params.iso
    
    if (newLanguage && language !== newLanguage) {
      dispatch(actions.app.setLanguage({ language: newLanguage }))
      dispatch(actions.app.init())
    }
  }, [dispatch, params, language])

  return (
      <>
        <HelmetProvider>
          <IntlProvider messages={translations || ''} key={language || 'en'} locale={language || 'en'} defaultLocale="en">
            {json && <AppGate json={json} />}
          </IntlProvider>
        </HelmetProvider>
      </>
  );
}

export default Page;
