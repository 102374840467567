import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../store";

import { actionTypes } from "./types";
import {ProjectSourceData} from "../../types/interface";

type AppState = {
  isInit: boolean;
  theme: string;
  language: string;
  currentSlide: number;
  previousSlide: number;
  currentStep: number;
  isOpenMenu: boolean;
  isVisibleHero: boolean;
  json: ProjectSourceData | null;
};

//
// Initial state
//

const initialState: AppState = {
  isInit: false,
  isVisibleHero: true,
  language: "",
  theme: "DEFAULT",
  currentSlide: 0,
  previousSlide: 0,
  currentStep: 0,
  isOpenMenu: false,
  json: null,
};

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    init: () => undefined,
    setJson: (state, action: actionTypes.setJson) => {
      const { json } = action.payload;
      state.json = json;
    },
    setLanguage: (state, action: actionTypes.setLanguage) => {
      const { language } = action.payload;
      state.language = language;
    },
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload;
      state.isInit = isInit;
    },
    setTheme: (state, { payload }: actionTypes.setTheme) => {
      const { theme } = payload;
      state.theme = theme;
    },
  },
});

export const { reducer, actions } = slice;

//
// Selectors
//

const root = (state: RootState) => state[slice.name];
const isInit = (state: RootState) => root(state).isInit;
const theme = (state: RootState) => root(state).theme;
const language = (state: RootState) => root(state).language;
const json = (state: RootState) => root(state).json;

export const selectors = {
  isInit,
  theme,
  language,
  json,
};
