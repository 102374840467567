import React, { FC, useMemo } from 'react'

import styled from 'styled-components'
import { ReactComponent as Logo } from './logo.svg';

import { sizes } from '../../theme/theme'
import Link, { LinkProps } from '../Link';

// types
export type HeaderProps = {
  className?: string
  center?: React.ReactNode
  left?: React.ReactNode
  right?: React.ReactNode
  link?: LinkProps
}

// styles
const Container = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  height: 9rem;
  background: var(--color-turquoise);
  @media (min-width: ${sizes.md}px) and (orientation: landscape) {
    height: 12rem;
  }
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
`
const Left = styled.div``
const StyledLogo = styled(Logo)`
  width: 14rem;
`
const Right = styled.div``

// component
const Header: FC<HeaderProps> = (props) => {
  const {
    className,
    left,
    right,
    link = null,
  } = props

  // state and refs and hooks

  // handlers

  // effects

  // renderers
  const renderLogo = useMemo(() => {
    if (link) return <Link {...link}><StyledLogo /></Link>
    return <StyledLogo />
  }, [link])

  // render
  return (
    <Container className={className}>
      <Wrapper>
        <Left>{left}</Left>
        {renderLogo}
        <Right>{right}</Right>
      </Wrapper>
    </Container>
  )
}

export default Header
