import {
  all,
  put,
  select,
  SagaReturnType,
  takeLatest, call,
} from "redux-saga/effects";

import { actions, selectors } from "..";

import {actionTypes} from "./types";

export default class AppSagas {
  static *init() {
    const isInit: SagaReturnType<typeof selectors.app.isInit> = yield select(
      selectors.app.isInit
    );

    if (!isInit) {
      yield all([
        put(actions.app.setIsInit({ isInit: true }))
      ])

    }
  }

  static *language({payload}: actionTypes.setLanguage) {
    const language = payload.language
    // @ts-ignore
    const result = yield call(fetch,`/datas/${language.toLowerCase()}.json?random=${Math.random()}`)

    // @ts-ignore
    const json = yield result.json()

    yield put(actions.app.setJson({json}))

  }

  static *loop() {
    yield all([
      takeLatest(actions.app.init, this.init),
      takeLatest(actions.app.setLanguage, this.language),
    ]);
  }

}
