import React, { FC, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'


import { sizes } from '../../theme/theme'

// types
export type ContentVideoBisProps = {
  className?: string
  source?: string
}

// styles
const Container = styled.div`
  max-width: calc(88rem + 4rem);
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  &:not(:first-child) {
    margin-top: 2.5rem;
  }
  &,
  & p,
  & li {
    line-height: 1.5;
  }
  @media (min-width: ${sizes.md}px) {
    margin-top: 2rem;
    &:not(:first-child) {
      margin-top: 4rem;
    }
  }
`
const Player = styled.div`
  position: relative;
`
const Play = styled.div<{ playing?: boolean }>`
  display: none;
  @media (min-width: ${sizes.md}px) {
    position : absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    color: var(--color-black, #000);
    background: var(--color-white, #fff);
    opacity: ${({playing}) => playing === true ? '0' : '1'};
    transition: opacity 100ms ease-in;
    &,
    & * {
      pointer-events: none
    }
    & svg {
      position : absolute;
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);
      width: 3rem;
      height: 3rem;
    }
  }
`

// component
const ContentVideoBis: FC<ContentVideoBisProps> = (props) => {
  const { className, source } = props
  const [playing, setPlaying] = useState(false)
  const refPlayer = useRef<ReactPlayer>(null)

  // handlers

  const handleSetPlay = useCallback(() => {
    if (refPlayer.current) setPlaying(true)
  }, [])

  const handleSetPause = useCallback(() => {
    if (refPlayer.current) setPlaying(false)
  }, [])

  // render
  return (
    <Container className={className}>
      <Player>
        <ReactPlayer 
          ref={refPlayer} 
          url={source} 
          width="100%" 
          height="auto"
          playing={playing}
          controls={true}
          onPlay={handleSetPlay}
          onPause={handleSetPause}
        />
        <Play playing={playing}>
          <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5615866,8.10002147 L3.87056367,0.225209313 C3.05219207,-0.33727727 2,0.225209313 2,1.12518784 L2,16.8748122 C2,17.7747907 3.05219207,18.3372773 3.87056367,17.7747907 L15.5615866,9.89997853 C16.1461378,9.44998927 16.1461378,8.55001073 15.5615866,8.10002147 L15.5615866,8.10002147 Z"/>
          </svg>
        </Play>
      </Player>
    </Container>
  )
}

export default React.memo(ContentVideoBis)
