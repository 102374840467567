import React, {FC, useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {getRedirectLanguage} from "../../helpers/redirectionUtils";

const Home: FC = ()=>{
  let navigate = useNavigate();

  useEffect(() => {
    const locale = getRedirectLanguage()
    navigate(`/${locale}`)
  }, [navigate])

    return (<></>);
}

export default Home;
