import { all, fork } from "redux-saga/effects";

import AppSagas from "./app/sagas";
import ApiSagas from "./api/sagas";

function* loop() {
  yield all([
    AppSagas.loop(),
    ApiSagas.loop(),
  ]);
}

export default function* rootSaga() {
  yield fork(loop);
}
