import React, { FC, useMemo } from 'react'

import styled from 'styled-components'
import Link, { LinkProps } from '../Link'

// types
export type FooterProps = {
  className?: string
  links?: LinkProps[]
}

// styles
const Container = styled.footer`
  max-width: calc(88rem + 4rem);
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  &:not(:first-child) {
    margin-top: 2.5rem;
  }
`
const Content = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem;
  margin: 0;
`
const Item = styled.li`
  &:not(:first-child) {
    margin-left: 20px;
  }
`
const StyledLink = styled(Link)`
  color: var(--color-white);
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.4;
`

// component
const Footer: FC<FooterProps> = (props) => {
  const { className, links } = props

  // state and refs

  // handlers

  // renderers

  const renderLinks = useMemo(() => {
    return (
      <Content>
        {links?.map((link, index) => (
            <Item key={index}>
              <StyledLink {...link} />
            </Item>
          )
        )}
      </Content>
    )
  }, [links])

  // effects

  // render
  return links && links?.length > 0 ? (
    <Container className={className}>
      {renderLinks}
    </Container>
  ) : null
}

export default Footer
