import React, { FC } from 'react'
import styled from 'styled-components'

import { sizes } from '../../theme/theme'

// types
export type ButtonProps = {
  className?: string
}

// styles
const Container = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 6.6rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  background: var(--color-blue-bright);
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-black);
  border-radius: 3.3rem;
  opacity: 0.95;
  transition: var(--transition-opacity);
  a & {
    text-decoration: none;
  }
  a:hover &,
  button:hover & {
    opacity: 1;
  }
  @media (min-width: ${sizes.md}px) {
    margin-top: 3rem;
  }
`

// component
const Button: FC<ButtonProps> = (props) => {
  const { className, children } = props

  // render
  return (
    <Container className={className}>
      {children}
    </Container>
  )
}

export default Button
