/* eslint-disable import/no-cycle */
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../store";
import * as configuration from "../../configuration";

import { actionTypes } from "./types";
import { Header } from "./types/state";

type ApiState = {
  headers: Header | Record<string, never>;
};

//
// Initial state
//

const initialState: ApiState = {
  headers: {
    ...(configuration.api.BASIC_AUTH
      ? { authorization: `Basic ${configuration.api.BASIC_AUTH}` }
      : {}),
  },
};

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: "api",
  initialState,
  reducers: {
    setHeaders: (state, action: actionTypes.setHeaders) => {
      const { headers } = action.payload;
      state.headers = headers;
    },
    setHeader: (state, action: actionTypes.setHeader) => {
      const { header } = action.payload;
      state.headers = { ...state.headers, ...header };
    },
  },
});

export const { reducer, actions } = slice;

//
// Selectors
//

const root = (state: RootState) => state[slice.name];
const headers = (state: RootState) => root(state).headers;

export const selectors = {
  headers,
};
