/* eslint-disable import/no-cycle */
import * as appRedux from "./app/redux";
import * as apiRedux from "./api/redux";

export const actions = {
  api: apiRedux.actions,
  app: appRedux.actions,
};

export const selectors = {
  api: apiRedux.selectors,
  app: appRedux.selectors,
};

export const reducers = {
  api: apiRedux.reducer,
  app: appRedux.reducer,
};
