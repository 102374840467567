
import { routesPath } from "./index";
import Home from "../relay/pages/Home";
import Page from "../relay/pages/Page";

// Routes using the Declaration layout
const routes = [
  {
    path: routesPath.homepage,
    name: "homepage",
    component: Home,
    props:{}
  },
  {
    path: routesPath.page,
    name: "page",
    component: Page,
    props:{}
  }
]

export default routes
