import * as appConfiguration from '../configuration'

export const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return String(appConfiguration.locales.default)
  }

  const lang = navigator && navigator.language && navigator.language.split('-')[0]

  const locale = appConfiguration.locales.open.find(
    (locale: String) =>
      locale && locale.toLocaleLowerCase() === lang.toLocaleLowerCase()
  )

  if (!locale) {
    return String(appConfiguration.locales.default)
  }

  return String(locale.toLowerCase())
}
