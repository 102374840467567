import React, { FC } from 'react'

import styled from 'styled-components'
import { sizes } from '../../theme/theme'

// types
export type ContentTextProps = {
  className?: string
}

// styles
const Container = styled.div`
  max-width: calc(88rem + 4rem);
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  &:not(:first-child) {
    margin-top: 2.5rem;
  }
  &,
  & p,
  & li {
    line-height: 1.5;
  }
  @media (min-width: ${sizes.md}px) {
    margin-top: 2rem;
    &:not(:first-child) {
      margin-top: 4rem;
    }
  }
`
const Content = styled.div`
  &,
  & p
  & li {
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.4;
  }
  & > * > a {
    color: inherit;
    text-decoration: underline;
  }
  & > * > a:hover {
    text-decoration: none;
  }
`

// component
const ContentText: FC<ContentTextProps> = (props) => {
  const { className, children } = props

  // render
  return children ? (
    <Container className={className}>
      <Content>
        {children}
      </Content>
    </Container>
  ) : null
}

export default ContentText
