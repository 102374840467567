import React, { FC, useMemo, useCallback, useEffect } from 'react'
import { useLockedBody } from 'usehooks-ts'
import styled, { css } from 'styled-components'

import Link, { LinkProps } from '../Link'
import { sizes } from '../../theme/theme'

// types
export type LangSwitcherProps = {
  className?: string
  links?: (LinkProps & { isCurrent?: boolean; iso?: string })[]
  isOpen?: boolean
  openHandler?: () => void
  closeHandler?: () => void
}

// styles
const Container = styled.nav`
  position: relative;
  color: var(--color-white);
`
const Overlay = styled.div<{ $isOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--zindex-overlay);
  background: var(--color-black);
  transition: var(--transition-opacity);
  ${props => props.$isOpen === true ? css`
    pointer-events: all;
    opacity: 0.5;
  ` : css`
    pointer-events: none;
    opacity: 0;
  `}
`
const Trigger = styled.button`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.3rem 0;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: inherit;
  background: linear-gradient(to bottom, transparent 0%, transparent calc(100% - 0.2rem), currentColor 100%);
  cursor: pointer;
  & svg {
    display: none;
  }
  @media (min-width: ${sizes.md}px) {
    background: transparent;
    & svg {
      display: block;
      width: 1.1rem;
      height: 0.7rem;
    }
    & svg:not(:first-child) {
      margin-left: 1.2rem;
    }
  }
`
const List = styled.ul<{ $isOpen?: boolean }>`
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--zindex-modal);
  min-width: 16rem;
  margin: 1rem 0 0;
  padding: 0;
  border-radius: 0.4rem;
  overflow: hidden;
  background: var(--color-white);
  transition: var(--transition-opacity), var(--transition-transform);
  ${props => props.$isOpen === true ? css`
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  ` : css`
    pointer-events: none;
    opacity: 0;
    transform: translateY(-1rem);
  `}
`
const Item = styled.li``
const StyledLink = styled(Link)<{ $isCurrent?: boolean }>`
  display: block;
  font-size: 1.2rem;
  padding: 0.2rem 0.4rem;
  text-align: center;
  transition: var(--transition-background-color);
  color: var(--color-black);
  text-decoration: none;
  ${(props) => props.$isCurrent === true ? css`
    background-color: rgba(237, 207, 198, 0.5);
    pointer-events: none;
  ` : css`
    &:hover {
      text-decoration: underline;
      background-color: rgba(238, 235, 231, 0.7);
    }
  `}
`

// component
const LangSwitcher: FC<LangSwitcherProps> = (props) => {
  const { className, links, isOpen = false, openHandler, closeHandler } = props

  // state and refs
  const [, setLockedOverflow] = useLockedBody()

  // handlers

  const handleOpen = useCallback(() => {
    openHandler?.()
  }, [openHandler])

  const handleClose = useCallback(() => {
    closeHandler?.()
  }, [closeHandler])

  // renderers

  const renderCurrent = useMemo(() => {
    const currentLanguage = links?.find((link) => link?.isCurrent === true) ?? false

    return currentLanguage && (
      <Trigger onClick={handleOpen}>
        <span>
          {currentLanguage?.iso ?? currentLanguage?.text}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 8">
          <path fill="currentColor" fillRule="evenodd" d="M0 1.75767 1.20459.5 5.5 4.98467 9.79541.5 11 1.75767 5.5 7.5 0 1.75767Z" clipRule="evenodd"/>
        </svg>
      </Trigger>
      )
  }, [handleOpen, links])

  const renderLinks = useMemo(() => {
    return (
      <List $isOpen={isOpen}>
        {links?.map((link, index) => (
            <Item key={index}>
              <StyledLink {...link} $isCurrent={link?.isCurrent === true} />
            </Item>
          )
        )}
      </List>
    )
  }, [isOpen, links])

  
  // effects
  useEffect(() => {
    setLockedOverflow(isOpen)
  }, [isOpen, setLockedOverflow])

  // render
  return links && links.length > 0 ? (
    <>
      <Container className={className}>
        {renderCurrent}
        {renderLinks}
      </Container>
      <Overlay aria-hidden="true" $isOpen={isOpen} onClick={handleClose} />
    </>
  ) : null
}

export default LangSwitcher
