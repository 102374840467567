import { useIntl as useIntlSource } from 'react-intl'

export const useIntl = () => {
  const intl = useIntlSource()

  return (id: any, values = {}) => intl.formatMessage({ id }, values)
}

export const t: any = (key: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl()

  return intl(key)
}
