import React, { FC, useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'

// import styled from 'styled-components'

// types
export type LinkProps = {
  className?: string
  text?: string
  href?: string
  handlerClick?: (ref: HTMLAnchorElement | null) => void
  title?: string
  target?: '_self' | '_blank'
}

// styles
const Container = styled.a<{ isActive: boolean }>`
  ${props => props.isActive !== true && css`
    cursor: default !important;
  `}
`

// component
const Link: FC<LinkProps> = (props) => {
  const { className, text, handlerClick, href, children, title, target } = props

  // state and refs
  const link = useRef<HTMLAnchorElement | null>(null)

  // handlers
  const handleOnClick = useCallback(() => {
    if (!handlerClick && (!href || href === '#')) return false

    handlerClick?.(link?.current)
  }, [handlerClick, href])

  // renderers

  // effects

  // render
  return (
    <Container 
      ref={link} 
      className={className} 
      href={href}
      onClick={handleOnClick} 
      title={title}
      target={target}
      isActive={!handlerClick && (!href || href === '#') ? false : true}
    >{children || text}</Container>
  )
}

export default Link
