import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import routes from "./routes";



const RoutesComponents = () => (
  <Router>
    <Routes>
        {routes.map(({
                     component: Component,
                     path,
                     props: componentProps = {},
         },
         index: number) =>{
            return  <Route
                key={index}
                path={path}
                element={<Component />} />
        })}

    </Routes>
  </Router>
);

export default RoutesComponents;
