import React, { useCallback, useEffect, useState,} from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useDebouncedCallback } from 'use-debounce/lib';
import RoutesComponents from "./router/RoutesComponents";


// global styles
const GlobalStyle = createGlobalStyle`
  // css variables applied to this context
  :root {
    // test
    --test: red;
    /* z-indexes */
    --zindex-default: 10;
    --zindex-navigation: 50;
    --zindex-overlay: 80;
    --zindex-modal: 100;
    --zindex-hidden: -1;
    // colors
    --color-error: #F11623;
    --color-turquoise: #006269;
    --color-pink: #EBBFB1;
    --color-pink-alt: #DEA18B;
    --color-blue: #ACB6D1;
    --color-blue-bright: #B1D3DB;
    --color-yellow: #F4D36F;
    --color-white-bubble: #EEEBE7;
    // greys etc...
    --color-white: #FFFFFF;
    --color-black: #000000;
    // transition (standard, basic ones)
    --transition: 200ms ease-in-out;
    --transition-color: color 200ms ease-in-out;
    --transition-border-color: border-color 200ms ease-in-out;
    --transition-background-color: background-color 200ms ease-in-out;
    --transition-opacity: opacity 200ms ease-in-out;
    --transition-transform: transform 200ms ease-in-out;
    // easings
    --ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
    --ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
  }
  html {
    min-height: 100%;
    font-size: 62.5%;
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-turquoise);
    color: var(--color-white);
    font-family: 'GucciSans', "Guccis Sans", sans-serif;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  a,
  button {
    background: transparent;
    border: 0 none;
    outline: 0 none;
    text-decoration: none;
  }
`

// APP styles
const Container = styled.div`
  position: relative;
`

function App() {

  const [viewportWidth, setViewportWidth] = useState<number | null>(0);

  // - handle vh
  const handleVH = useCallback(() => {
    if (window) {
      const target = document.querySelector("#root") as HTMLElement;
      target?.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    }
  }, []);

  // - stuff that refresh on actual resize go here
  const handleResize = useCallback(() => {
    const currentWidth = window?.innerWidth ?? 0;
    // only stuff when actual resolution change
    if (currentWidth !== viewportWidth) {
        setViewportWidth(currentWidth);
    }
    // stuff when the viewport changes in any way (nav bar on / off)
    handleVH();
  }, [viewportWidth, handleVH]);
  const handleResizeDebounced = useDebouncedCallback(handleResize, 50);

  useEffect(() => {
    handleResizeDebounced();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResizeDebounced);
    }
    return () => {
      try {
        if (typeof window !== "undefined") {
          window.removeEventListener("resize", handleResizeDebounced);
        }
      } catch (e) {}
    };
  }, [handleResizeDebounced]);

  // render
  return (
    <Container>
      {/* styles */}
      <GlobalStyle />
      <RoutesComponents />
    </Container>
  );
}

export default App;
